// Reference: https://www.robinwieruch.de/react-router-private-routes/
import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spin } from 'antd';

import { ProtectedRoute } from './ProtectedRoute';
import styled from '@emotion/styled';
import TaskManagement from '@pages/Tasks/TaskManagement';
import AssetManagement from '@pages/Assets/AssetManagement';
import GenderReportPage from '@pages/Reports/GenderReportPage';
import HeadcountReportPage from '@pages/Reports/HeadcountReportPage';
import Settings from '@pages/Settings/Settings';
import KnowledgeBase from '@pages/KnowledgeBase/KnowledgeBase';
// Removed the import for KnowledgeBase due to the error

const LoginForm = lazy(() => import('@pages/LoginForm/LoginForm'));
const RegisterForm = lazy(() => import('@pages/RegisterForm/RegisterForm'));
const Dashboard = lazy(() => import('@pages/Dashboard/Dashboard'));
const Directory = lazy(() => import('@pages/Directory/Directory'));
const EmployeeProfile = lazy(() => import('@pages/EmployeeProfile/EmployeeProfile'));
const Holidays = lazy(() => import('@pages/Holidays/Holidays'));

const Departments = lazy(() => import('@pages/Departments/Departments'));
const LeaveSettings = lazy(() => import('@pages/LeaveSettings/LeaveSettings'));
const PeopleSettings = lazy(() => import('@pages/PeopleSettings/PeopleSettings'));
const Positions = lazy(() => import('@pages/Positions/Positions'));
const Termination = lazy(() => import('@pages/Termination/Termination'));
const Promotion = lazy(() => import('@pages/Promotion/Promotion'));
const Resignation = lazy(() => import('@pages/Resignation/Resignation'));
const Locations = lazy(() => import('@pages/Locations/Locations'));
const Teams = lazy(() => import('@pages/Teams/Teams'));
const TimeOffOverview = lazy(() => import('@pages/TimeOffOverview/TimeOffOverview'));

export const CenterLoader = styled(Spin)`
  top: calc(50% - 65px);
  left: 50%;
  position: relative;
  transform: translate(-50%, -50%);
`;
export const RouteManager: React.FC = () => {
  const loading = false;

  return (
    <>
      {loading ? (
        <CenterLoader size='large'></CenterLoader>
      ) : (
        <>
          <Suspense fallback={<CenterLoader size='large'></CenterLoader>}>
            <Routes>
              <Route path='/' element={<LoginForm />} />
              <Route path='/register' element={<RegisterForm />} />
              <Route
                path='/dashboard'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/tasks'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <TaskManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/asset/items'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <AssetManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/reports/gender'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <GenderReportPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/reports/headcount'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <HeadcountReportPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/settings'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/people/me'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <EmployeeProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/people/directory'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Directory />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/people/directory/:employee_code'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <EmployeeProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/people/departments'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Departments />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/people/teams'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Teams />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/people/locations'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Locations />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/people/positions'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Positions />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/people/settings'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <PeopleSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/timeoff/overview'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <TimeOffOverview />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/timeoff/settings'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <LeaveSettings />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/timeoff/requests'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Holidays />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/timeoff/holidays'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Holidays />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/performance/promotion'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Promotion />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/performance/resignation'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Resignation />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/performance/termination'
                element={
                  <ProtectedRoute redirectPath='/' isAllowed={true}>
                    <Termination />
                  </ProtectedRoute>
                }
              />
              <Route path='/knowledge-base' element={<KnowledgeBase />} />
            </Routes>
          </Suspense>
        </>
      )}
    </>
  );
};
