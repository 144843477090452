import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Card, Input, Button, Table, Checkbox, Upload, Pagination } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { API_ENDPOINT } from '@src/index';
import { ColumnsType } from 'antd/es/table';

const { Dragger } = Upload;

const KnowledgeBase = () => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [inputUrl, setInputUrl] = useState('');
  const [linkState, setLinkState] = useState({});
  const [currentLinksPage, setCurrentLinksPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const fetchTrainingMaterials = useCallback(async () => {
    // Fetch training materials from the backend
  }, []);

  useEffect(() => {
    fetchTrainingMaterials();
  }, [fetchTrainingMaterials]);

  const handleAddURL = async () => {
    if (!inputUrl) {
      message.error('Please enter a URL.');
      return;
    }
    setIsUploading(true);
    // Simulate URL processing
    setTimeout(() => {
      setLinkState((prev) => ({ ...prev, [inputUrl]: false }));
      setInputUrl('');
      setIsUploading(false);
      message.success('URL added successfully.');
    }, 1000);
  };

  const handlePageChange = (page: number) => {
    setCurrentLinksPage(page);
  };

  const handleItemsPerPageChange = (current: number, size: number) => {
    setItemsPerPage(size);
    setCurrentLinksPage(1);
  };

  const columns = [
    {
      title: 'Select',
      dataIndex: 'selected',
      key: 'selected',
      render: (text: string, record: { selected: boolean; training_material_id: string }) => (
        <Checkbox
          checked={record.selected}
          onChange={() => toggleFileSelection(record.training_material_id)}
        />
      ),
      align: 'center',
    },
    {
      title: 'Training Material',
      dataIndex: 'training_material_title',
      key: 'training_material_title',
      render: (name: string) => <span>{name}</span>,
      align: 'center',
    },
    // Add more columns as needed
  ];

  const fileUploadURL = `${API_ENDPOINT}/upload`; // Define the file upload URL

  const props = {
    name: 'file',
    multiple: true,
    action: `${fileUploadURL}?train=true`,
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    beforeUpload: (file: { type: string; }) => {
      const isPDF = file.type === 'application/pdf';
      const isDocx = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      const isTxt = file.type === 'text/plain';
      const isCsv = file.type === 'text/csv';
      if (!isPDF && !isDocx && !isTxt && !isCsv) {
        message.error('You can only upload PDF, CSV, DOCX or TXT files!');
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info: { file: { name?: string; status?: string; }; }) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const toggleFileSelection = (id: any) => {
    setFileList(
      fileList.map((file) =>
        file.training_material_id === id
          ? { ...file, selected: !file.selected }
          : file
      )
    );
  };

  return (
    <div className='container mx-auto p-8'>
      <Card className='rounded-lg border border-gray-200 shadow-md'>
        <Tabs defaultActiveKey='1'>
          <Tabs.TabPane tab='File Upload' key='1'>
            <Dragger {...props} method="put">
              <p className='ant-upload-drag-icon'>
                <UploadOutlined />
              </p>
              <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            </Dragger>
          </Tabs.TabPane>
          <Tabs.TabPane tab='Website URL' key='2'>
            <Input
              placeholder='Enter website URL'
              value={inputUrl}
              onChange={(e) => setInputUrl(e.target.value)}
            />
            <Button onClick={handleAddURL}>Add URL</Button>
          </Tabs.TabPane>
        </Tabs>
      </Card>
      <Card className='rounded-lg border border-gray-200 mt-8 shadow-md'>
        <Table columns={columns as ColumnsType<any>} dataSource={fileList} rowKey='training_material_id' />
        <Pagination
          current={currentLinksPage}
          onChange={handlePageChange}
          total={Object.keys(linkState).length}
          pageSize={itemsPerPage}
          showSizeChanger={true}
          onShowSizeChange={handleItemsPerPageChange}
        />
      </Card>
    </div>
  );
};

export default KnowledgeBase;
