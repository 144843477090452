import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AccountContext } from '../pages/LoginForm/Account';

export const ProtectedRoute = ({ isAllowed, redirectPath = '/landing', children }: any) => {
  const { getSession } = React.useContext(AccountContext);

  getSession().then((session: any) => {
    if (!isAllowed || !session) {
      sessionStorage.removeItem('sessionData');
      return <Navigate to={redirectPath} replace />;
    } else {
      sessionStorage.setItem('sessionData', JSON.stringify(session));
    }
  });

  return children ? children : <Outlet />;
};
