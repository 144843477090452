import React from 'react';
import { createRoot } from 'react-dom/client';

import { HashRouter } from 'react-router-dom';

import App from './App';
import './firebase-init';
import { Account } from '@pages/LoginForm/Account';

// Heroku path was "https://zealsightapi.herokuapp.com/api"
// const API_SERVICE_PROD_PATH = "https://zealsightapi.onrender.com/api";
const API_SERVICE_PROD_PATH = 'https://9hpavdmfzc.us-east-1.awsapprunner.com/api';
const LOCAL_SERVICE_PATH = 'http://localhost:8080/api';

export const API_ENDPOINT = window.location.hostname.includes('zealsight.com')
  ? API_SERVICE_PROD_PATH
  : LOCAL_SERVICE_PATH;

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Account>
        <App />
      </Account>
    </HashRouter>
  </React.StrictMode>
);
