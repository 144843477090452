import UserPool from '@pages/RegisterForm/UserPool';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { message } from 'antd';
import React, { createContext, useState } from 'react';

interface SessionData {
  idToken: { jwtToken: string };
  accessToken: { jwtToken: string };
  email: string;
  loginType?: 'google' | 'facebook';
}

const AccountContext = createContext({
  authenticate: async (
    Username: string,
    Password: string,
    loginType?: 'google' | 'facebook'
  ): Promise<SessionData> => {
    throw new Error('method not implemented');
  },
  getSession: async (): Promise<SessionData | null> => {
    throw new Error('method not implemented');
  },
  setStatus: (status: boolean): void => {
    throw new Error('method not implemented');
  },
  logout: async (): Promise<void> => {
    throw new Error('method not implemented');
  },
  status: false,
});

const Account = (props: any) => {
  const [status, setStatus] = useState(() => {
    return !!sessionStorage.getItem('sessionData');
  });

  const logout = async () => {
    const sessionData = sessionStorage.getItem('sessionData');
    if (sessionData) {
      const parsedSession = JSON.parse(sessionData) as SessionData;

      // Handle Cognito logout
      if (!parsedSession.loginType) {
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
          cognitoUser.signOut();
        }
      }

      // Handle Google logout
      if (parsedSession.loginType === 'google' && window.google?.accounts?.id) {
        window.google.accounts.id.disableAutoSelect();
      }

      // Handle Facebook logout
      if (parsedSession.loginType === 'facebook' && window.FB) {
        window.FB.logout(() => {
          console.log('Logged out from Facebook');
        });
      }
    }

    // Clear session storage and update status
    sessionStorage.removeItem('sessionData');
    setStatus(false);
  };

  const getSession = async (): Promise<SessionData | null> => {
    const sessionData = sessionStorage.getItem('sessionData');
    if (sessionData) {
      return JSON.parse(sessionData) as SessionData;
    }

    // Check for Cognito session
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err: any, session: any) => {
          if (err) {
            reject(null);
          } else {
            resolve(session);
          }
        });
      } else {
        resolve(null);
      }
    });
  };

  const authenticate = async (
    Username: string,
    Password: string,
    loginType?: 'google' | 'facebook'
  ): Promise<SessionData> => {
    if (!Username) {
      throw new Error('Username is required');
    }

    // For social logins, we'll handle them differently
    if (loginType === 'google' || loginType === 'facebook') {
      // For now, we'll just store the session data
      const sessionData: SessionData = {
        idToken: { jwtToken: Password }, // Using the social token as JWT
        accessToken: { jwtToken: Password },
        email: Username,
        loginType: loginType,
      };
      sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
      setStatus(true);
      return sessionData;
    }

    // Regular Cognito authentication
    if (!Password) {
      throw new Error('Password is required for regular login');
    }

    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });
      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const sessionData = data as unknown as SessionData;
          sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
          setStatus(true);
          resolve(sessionData);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log('new password required', data);
          message.info('New Password required');
          resolve(data as unknown as SessionData);
        },
      });
    });
  };

  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout, status, setStatus }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
