import React, { useState, useEffect } from 'react';
import { Layout, Typography, Tabs, Input, Button, Card, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import NewTaskModal from './NewTaskModal';
import TaskList from './TaskList';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;

export interface Task {
  id: string;
  title: string;
  description: string;
}

const TaskManagement: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    // Simulating API call
    setTimeout(() => {
      setTasks([
        {
          id: '1',
          title: 'Example task',
          description: 'Description goes here',
        },
      ]);
      setLoading(false);
    }, 1000);
  }, []);

  const handleNewTask = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleTaskCreate = (newTask: Task) => {
    setTasks([...tasks, newTask]);
    setIsModalVisible(false);
  };

  return (
    <Layout>
      <Content style={{ padding: '24px' }}>
        <Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <Title level={2} style={{ margin: 0 }}>
              Tasks
            </Title>
            <Tabs defaultActiveKey='my' style={{ marginBottom: 0 }}>
              <TabPane tab='My' key='my' />
              <TabPane tab='Team' key='team' />
              <TabPane tab='Company' key='company' />
            </Tabs>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <Search placeholder='Search...' style={{ width: 200 }} />
            <Button type='primary' icon={<PlusOutlined />} onClick={handleNewTask}>
              New task
            </Button>
          </div>

          <Tabs defaultActiveKey='todo'>
            <TabPane tab={`To do ${tasks.length}`} key='todo'>
              {loading ? (
                <Skeleton active avatar={true}>
                  <Card style={{ width: '100%', marginBottom: '16px' }}>
                    <Card.Meta title='' description='' />
                  </Card>
                </Skeleton>
              ) : (
                <TaskList tasks={tasks} />
              )}
            </TabPane>
            <TabPane tab='Created' key='created' />
            <TabPane tab='Completed' key='completed' />
          </Tabs>
        </Card>
      </Content>
      <NewTaskModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onCreateTask={handleTaskCreate}
      />
    </Layout>
  );
};

export default TaskManagement;
