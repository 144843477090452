import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { ThemeProvider, useTheme } from './useTheme';
import { ConfigProvider, Layout, Spin, theme } from 'antd';
import { AccountContext } from '@pages/LoginForm/Account';
import { RouteManager } from './route/RouteManager';
import './index.css';

const AppHeader = lazy(() => import('@components/AppHeader'));
const Sidebar = lazy(() => import('@components/Sidebar'));

const { Content } = Layout;

const ThemedApp: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const currentTheme = useTheme();

  return (
    <ConfigProvider
      theme={{
        algorithm: currentTheme === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      {children}
    </ConfigProvider>
  );
};

function App() {
  const [collapsed, setCollapsed] = useState<boolean>(localStorage.getItem('navState') === 'true');
  const { status } = useContext(AccountContext);
  const [isAuthenticated, setIsAuthenticated] = useState(status);

  useEffect(() => {
    const sessionData = sessionStorage.getItem('sessionData');
    if (sessionData) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [status]);

  return (
    <ThemeProvider>
      <ThemedApp>
        <Layout style={{ minHeight: '100vh' }}>
          {isAuthenticated && (
            <Suspense fallback={<Spin size='large' />}>
              <Sidebar collapsed={collapsed} />
            </Suspense>
          )}
          <Layout className='site-layout'>
            {isAuthenticated && (
              <Suspense fallback={<Spin size='large' />}>
                <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} />
              </Suspense>
            )}
            <Content
              style={{
                borderRadius: '5px',
                minHeight: 280,
                maxHeight: 'calc(100vh - 64px)',
                overflow: 'auto',
              }}
            >
              <RouteManager />
            </Content>
          </Layout>
        </Layout>
      </ThemedApp>
    </ThemeProvider>
  );
}

export default App;
