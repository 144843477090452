import React from 'react';
import { List, Button, Skeleton } from 'antd';
import { Task } from './TaskManagement';
import Paragraph from 'antd/lib/skeleton/Paragraph';

interface TaskListProps {
  tasks: Task[];
}

const TaskList: React.FC<TaskListProps> = ({ tasks }) => {
  const handleCompleteTask = (taskId: string) => {
    console.log(`Complete task ${taskId}`);
  };

  return (
    <List
      dataSource={tasks}
      renderItem={(task, index) => (
        <List.Item
          actions={[
            <Button key='complete' onClick={() => handleCompleteTask(task.id || index.toString())}>
              Complete task
            </Button>,
          ]}
        >
          <List.Item.Meta
            title={task.title || <Skeleton.Input style={{ width: 200 }} />}
            description={task.description || <Paragraph rows={2} style={{ marginBottom: 0 }} />}
          />
        </List.Item>
      )}
    />
  );
};

export default TaskList;
